import React, { Fragment } from 'react';
import { graphql } from 'gatsby';
import ScrollToSection from './ScrollToTop';

import '../pages/mannequin.css';

const Mannequin = () => {
    return (
        <Fragment>
        <span id="organs-tip"></span>
        <div id="organswrapper">
            <div id="frt_base">
                <svg version="1.1" xmlns="http://www.w3.org/2000/svg" width="1390px" height="1370" viewBox="0 0 1390 1370" space="preserve">
                    <image overflow="visible" width="1390" height="1370" href="../front-image.png" />
                    <ScrollToSection loc='head'>
                        <path id="frt_1" className="st0 light-part" d="M418.3,70C416.7,38,382,19,363,19c-30.8,0-50.2,31.5-53.2,44.5c-1.9,8.3-2.8,23.5-2.5,28.2s1,12.3,0.7,16.2c2,7.7,6.7,23,6.7,33c0.7,5.2,1.2,12.5,3.3,18.8c3,4,22.5,23.3,44.2,23.3s36.5-8.7,45.7-23c2.6-5.6,5-15.2,4.8-18.6c-0.7-5.1,4.7-28,5.7-32.7C419,101.3,420,102,418.3,70z"/>
                    </ScrollToSection>
                    <ScrollToSection loc='head'>
                        <path id="frt_17" className="st0 light-part" d="M1082.2,60.8c-25.9,0-43.8,13.7-50.4,35.5c-3.2,10.6-3.6,24.1-3.6,40.5c0,2.2,0.1,4.4,0.2,6.5c0.5,11.2,2.3,22.2,4.6,31.6c3.2,13.1,7.4,23.3,10.5,26.9c3.5,5.3,9.4,9.8,13.8,13.2c7.5,5.9,14.7,9,26,9c11.7,0,20.1-3.8,27.3-10.2c3.9-3.5,8.8-8.4,10.5-12.2c3.4-6.6,6.2-17.3,8.3-28c2.5-12.6,4.1-25.3,4.7-31.5c0.1-1.5,0.2-2.7,0.2-3.3C1134.2,123.7,1142.2,60.8,1082.2,60.8z"/>
                    </ScrollToSection>
                    <ScrollToSection loc='trunk'>
                        <path id="frt_9" className="st0 blue-part" d="M488,394c-0.5-0.5-6.6,18.4-8,21.2s-3.5,6.5-4.1,9.8c-0.6,3.2-4.1,11.8-5.3,15s-5.2,10.4-5.5,16.5c-0.2,6.1-3.1,19.4-2.8,27.6c0.3,8.2,6.8,43.7,3.8,52.8c-3,9.2-4.2,16.7-2.5,21.2c-12.3,13-28.7,33.5-98.8,33.5c-70.1,0-88.9-23-100.5-34.5c0.9-3.8-0.6-5.8-1.4-8.8c-1.2-4.5-4-20,0.5-40.2s1.2-35.8-0.5-41.5s-0.2-11-3.8-17.8s-6.1-18.9-8.6-24.6c-2.5-5.7-11.7-30.8-12.2-30.8c0.2-13.6-0.8-50.1,0.2-85.7c0.1-2.5,0.4-4.9,0.8-7.4c6.1-31.9,44.1-54.8,52.1-58.3c5.2,1.5,10.8,2.4,16.1,2.2c15.7-0.8,41.2-2.2,45.3,3.7c4.2,5.8,15.8,6,19.7,0c3.8-6,38-6.2,50.8-4.3c5,0.7,9.8,0.8,14.4,0.4c10.8,2.9,46.5,30.4,48.6,51.1C487.7,307,488.7,380.7,488,394z"/>
                    </ScrollToSection>
                    <ScrollToSection loc='trunk'>
                        <path id="frt_25" className="st0 blue-part" d="M1173.3,469.5c-1.9,22.5-4.2,57.5-2.5,61.9c2.5,6.2,8,19.5,9.2,23.2c0.9,2.8,3.2,9.4,5.4,14.7c-4.1,8.3-27.2,39.6-104,39.6s-98.3-32.1-101.9-39.6c5.5-13.4,13.7-33.5,14.8-41.5c0.2-1.6,0.3-4.3,0.3-7.8c0-12.1-1.2-33.4-2.5-48.4c-0.7-8.7-1.4-15.3-1.9-16.8c-0.3-1-0.8-2.8-1.3-5c-2-8.5-5.2-23.9-5.7-28.8c0,0,0,0,0,0c-3.8-4.5-7.6-13.2-8.9-21.2c0-0.6,0-1.2,0-1.8c0.1-18,3.7-20.9,7-32.7c2.7-9.6,4.8-20.2,2.8-33.1c-0.5-3.7-0.8-7.4-0.7-11c0.4-24.8,15.2-46.1,48.1-49.3c4,3,16,6.7,23.7,5.3c7.7-1.3,14,3.7,18.3,7.7c4.3,4,11.7,4,15.3,0c3.7-4,12-9.3,24.3-7.3c12.3,2,16.3-1,26.3-6c34.4,5.3,41.9,26.2,42.6,46.3c0.4,10.7-1.2,21.2-1.6,29c-0.4,6.4,1,11.2,2.8,16c1.1,2.9,2.3,5.8,3.4,9c0.2,0.6,0.4,1.3,0.6,2c0.4,1.3,0.8,2.7,1.2,4.2c0.3,1.5,0.6,3,0.9,4.7c0.1,0.4,0.1,0.8,0.2,1.3c0.6,4.8,0.6,10.3-0.3,17.2c-0.4,4.4-5,15.4-8.3,19.4c0,0,0,0,0,0c-0.7,5.8-4.8,26.7-5.8,30C1174.9,451.8,1174.1,459.4,1173.3,469.5z"/>
                    </ScrollToSection>
                    <ScrollToSection loc='arm'>
                        <path id="frt_10" className="st0 grey-part" d="M291.6,242c-8,3.5-46,26.4-52.1,58.3c-0.5,2.4-0.7,4.8-0.8,7.3c-1,35.7,0,72.1-0.2,85.7c-0.6,0.2-20.5,41.4-24,48.1c-1.2,2.3-2.1,4.2-2.8,5.7c-1.3,2.9-1.9,4.8-2.2,7.2c-0.4,3.5-5.3,30.8-11.5,47.2c-0.2,0.7-0.5,1.3-0.8,2c-6.5,16-19,39.8-32,55.8S145,587,140.3,594.5c-1,1.6-2.3,3.9-3.7,6.6c-10.1,4.9-40.9-9.6-42.6-18.6c1.8-4.5,3.6-9.4,5.3-14.5c7.9-23.3,14.7-50.6,16.7-62.5c1.1-6.7,3.5-15.1,6.5-23.8c6.8-19.9,16.6-41.5,22.5-48.9c7.3-9.2,6.9-10.9,8.8-18.7c0.3-1.3,0.7-2.8,1.2-4.5c3.5-12,13.5-43.5,18.8-51.2c4.5-6.7,7-8.7,7.4-14.8c0.1-1,0.1-2,0.1-3.2c-0.2-8.8-2.8-41.2,9.2-65.8s42.2-35.5,53.5-37.8s18.8-0.8,26.8-4.2C272.8,234.2,281.5,239.1,291.6,242z"/>
                    </ScrollToSection>
                    <ScrollToSection loc='arm'>
                        <path id="frt_11" className="st0 grey-part" d="M588.9,599.1c-3-5.2-14.9-22.6-19.5-28.2C564.8,565.2,536,523,529,505c-0.7-1.9-3.9-10.8-6.1-20.2c-2.7-11.6-4.4-24-4.4-25.3c0-6-4.3-11.8-7-17.3S488.1,394.1,488,394c0.7-13.3-0.3-87-1.5-99c-2-20.8-37.8-48.2-48.6-51.1c11-1.1,20.4-5.1,25.3-10c10.5-0.1,50.6,10.3,63.6,26.7s19.8,49.5,17.9,68.5c-0.7,6.5-0.4,11,0.3,14.4c1.1,5,3.4,7.8,5.7,11.1c4.1,6,20.4,46,22,57.2c1.2,8.1,3.2,12,6.5,17.2c1.3,2.1,12.8,22.9,17.7,32.2c4.1,7.6,7,16.2,9,24.1c6,23.2,14.8,64.2,18.8,74.2c2.2,5.5,5.2,13.9,8.1,21.6C634.7,590.7,595.4,609.3,588.9,599.1z"/>
                    </ScrollToSection>
                    <ScrollToSection loc='arm'>
                        <path id="frt_26" className="st0 grey-part" d="M983.5,321.1c-0.1,3.6,0.2,7.3,0.7,11c1.9,12.9-0.1,23.5-2.8,33.1c-3.5,12.2-7.2,14.9-7,34.5c-1,11-14.3,45.5-16.6,50.6c-0.6,1.3-1.4,3.1-2.2,5.3c0,0,0,0,0,0c-2.5,6.4-5.7,15.6-6.8,22c-1.5,8.7-6.3,28.2-8,37.7c-0.2,1.2-0.5,2.7-1,4.4c-2.8,11.2-9.5,31.1-15.5,44.7c-7,15.7-20.2,43.8-28.8,59.1c-2.3,4.1-4.5,8.9-6.5,13.4c-5.2,1-14.5-0.8-20-3.2c-5.2-2.2-10.6-4.6-12.4-8.9c5-13.3,11.5-30,15.9-74.5c1.9-19.6,9.7-41.6,16-56.5c3.5-8.3,6.6-14.5,8-16.7c3.8-6.2,6.2-16.2,9.5-29.8c0.2-0.9,0.5-2.1,0.9-3.4c5-17,22.1-61.7,22.8-82.6c0.1-2.9,0.3-5.8,0.6-8.8c1.8-20.5,7.1-42.7,8.4-49.7c8.7-45.3,37.5-43.8,45.5-44.5c20.7,3.5,43.3,10.5,47.3,13.5C998.7,275.1,983.9,296.3,983.5,321.1z"/>
                    </ScrollToSection>
                    <ScrollToSection loc='arm'>
                        <path id="frt_27" className="st0 grey-part" d="M1308.3,625c-1.8,4.3-7.2,6.6-12.3,8.8c-5.5,2.4-14.7,4.1-19.9,3.2c-6-12.7-22.3-45.9-29.8-60c-9-17-17.3-45-20-52c0,0,0,0,0,0c-1.3-3.5-2.8-10.6-4.2-17.7c-1.4-7.2-2.7-14.2-3.5-17c-1.7-5.5-3.6-16.4-4.5-21.3c-0.7-4.1-3.2-10.2-5.9-16.3c-2.2-5-4.4-10-5.8-13.7c-3-8.2-9.5-28-10.3-30.8c-0.8-2.8-2.3-5-2.7-6.9c2.5-20-2.5-29.2-6-38.4c-1.8-4.8-3.2-9.6-2.8-16c0.4-7.8,2-18.3,1.6-29v0c-0.7-20.1-8.2-41-42.6-46.3c13.2-6.6,19.4-9.1,36.7-14.6c14,0.9,28.2,3.7,39,18.6c14,19.5,19,73.7,19,79.2c0,0,0,0,0,0c0,2.2,0.3,6.1,1.3,11.8c0.6,3.4,1.4,7.4,2.6,12.2c1.8,7.4,4.4,16.6,8.1,27.7c4.9,14.7,8.3,26.1,10.5,34.4c0.2,0.8,0.4,1.6,0.6,2.4c2,7.4,2.9,12,3.4,13.7c1,3.8,2.8,12,9,23.8c0.9,1.7,1.9,3.8,3.1,6.2c1.2,2.6,2.5,5.6,3.9,8.8c6.3,15,13.6,36.2,16.1,54.5c3.5,26,8.5,56.2,15,73.2C1307.9,624.1,1308.1,624.5,1308.3,625z"/>
                    </ScrollToSection>
                    <ScrollToSection loc='hand'>
                        <path id="frt_12" className="st0 blue-part" d="M123.5,630.7c-2.6-2-5.5-4-8.4-5.8C118.1,626.7,120.9,628.7,123.5,630.7c-0.4,1.3-0.6,2.5-0.7,3.5c-0.8,8.5,1.7,10.5-1,20.2c-2.7,9.7-3.5,17.5-7,28.5c-1.3,4.1-3.9,9-5.6,13.8c-2.9,8.1-4.9,16-6.5,19.4c-2.7,5.3-4.5,14.3-5.7,16.3s-6,4.9-8.2,2.5c-2.2-2.4-1.8-5.5,0-12s5.8-20.6,7.1-25c1.4-4.4,2.2-6.9,1.1-7c-1.1-0.1-2.2,1.6-3.6,5.5c-1.4,3.9-4.6,16.7-6.1,22.4c-1.5,5.6-7.5,23.9-9.4,27.1c-1.9,3.2-4.5,6.2-8.8,4.7c-4.2-1.5-3.6-6.5-2.5-10.7c1.1-4.3,4.4-15.4,5.4-20.4s6.4-23.4,7.1-25.6c0.8-2.3,1-4.6-0.7-4.8c-1.8-0.1-2.5,3.4-3.9,7.9s-5.1,15.5-7.1,24s-5.6,22.4-7.4,26.1c-1.8,3.8-4.9,7.4-9.5,5.6s-3-7.8-2.8-10.5s4.5-18.6,5.2-22.5s6.5-24.8,7.9-28.6c1.4-3.9,3.7-8.9,1.7-9.7c-2-0.9-3,4.5-4.4,7.7c-1.4,3.3-6.9,19.3-8,22c-1.1,2.8-3.2,12.5-4,15.5c-0.8,3-4.4,11.5-8.7,10.6c-4.2-0.9-6.4-4.6-5.1-12.6s4.8-22,6.8-29.2c2-7.3,5.2-18,5.9-21.1c0.6-3.1,9.1-23.9,9.1-25.5s-3.8-1.3-7.6-0.1c-6.5,2-12.1,8.9-15.5,10.5c-3.4,1.6-8.9,3.4-13,1.5c-4.1-1.9-4.1-8.4,0-10.5c4.1-2.1,7.3-7.5,11.9-10.5s24.4-19.5,37.8-23.2c7.5-2.1,10.9-3.5,12.8-5.1c1.5,0.1,3.3,0.3,5.3,0.8c-2-0.5-3.8-0.8-5.3-0.8c1.5-1.3,2.1-2.7,3-4.6c0.7-1.5,5.3-11.2,10.5-24.1c1.7,9,32.5,23.5,42.6,18.6C131.9,609.8,125.7,622.9,123.5,630.7z"/>
                    </ScrollToSection>
                    <ScrollToSection loc='hand'>
                        <path id="frt_13" className="st0 blue-part" d="M712.5,655c-0.8,6.8-6.8,6.6-9.2,6.4c-2.5-0.3-9.6-2-13.4-7c-3.8-5-9.9-6.8-13-7.6c-3.1-0.9-3.8,1.2-2.5,3.9c1.2,2.6,7.4,19.6,8.6,26c1.2,6.4,5,16.1,6.5,23c1.5,6.9,6.3,24.7,6.2,27.4s-0.8,9.6-4.4,10.1c-3.6,0.5-8-1.4-10.6-7.1s-2.4-12.4-4.1-17.4c-1.7-5-7.1-21.4-8.4-25.5c-1.2-4.1-2.5-5.6-3.5-5.2c-1,0.4,0.2,2.6,1.1,5.7c0.9,3.1,6.4,20.1,7.2,24c0.9,3.9,4.1,19.8,5.5,25c1.4,5.3,2.1,12.3-0.9,15.3s-5.9,1.8-8.6-0.9c-3.4-3.2-5.5-14.1-6-17.8s-9.4-32.5-10.2-35.5c-0.9-3-1.8-8.8-3.8-8.6c-2,0.1-1.4,3.1-0.6,5.1s5.6,17.1,6.1,21.2s6.6,24,6.4,27.6c-0.2,3.6-1.1,8.1-5.1,8.4s-7.3-5-9-9.8c-1.8-4.8-5.3-17.1-6.3-21.8s-5.7-21.2-6.9-24.1c-1.1-2.9-1.5-5.6-3.2-5.6s-0.6,2.6,0.2,5.1s5.6,18.5,6.5,21.7c0.9,3.3,3.2,10.6,2.5,14.1s-5.5,5-8.2,2.8c-3.2-2.6-11.1-27.5-12.6-32s-6.3-14-8.5-22.9c-2.3-8.9-5.4-23-6.4-32.1s0.9-14.5-0.1-16.2c-0.5-0.8-3.3-7.1-6.5-14c-3.3-7.2-7-15-8.5-17.7c6.5,10.2,45.8-8.5,44-18.1c2.4,6.3,4.7,12.1,6.4,15.2c3.8,6.8,1,11,9.5,15c4.2,2,11.1,5.1,17.5,8.1c6.3,3,12.2,5.9,14.3,7.4c4.2,3,19,10.8,21.8,14.5S713.2,648.2,712.5,655z"/>
                    </ScrollToSection>
                    <ScrollToSection loc='hand'>
                        <path id="frt_28" className="st0 blue-part" d="M889,637c-0.9,2-1.8,4-2.6,5.8c-3.3,7.3-5.9,13.3-6.9,16.8v0c-0.3,1-0.5,1.8-0.5,2.4c-0.2,4.3-3.5,18.7-4.7,23c-1.2,4.3-1.3,11.8-2.5,18.3c-1.2,6.5-3.2,12.6-4.4,17.5c-1.1,4.9-2.5,13.1-3.8,15.9s-4.1,12-5,13.4c-0.9,1.4-4.1,3-5.9-0.1c-1.8-3.1,0.2-7.5,1-9s0.9-7.2,1.8-9.6c0.9-2.4,0.9-10.9,1.8-14.2c0.9-3.4-0.5-5.8-1.1-1.8c-0.6,4-2.8,16.1-4.1,20.2c-1.3,4.1-4.9,14.9-5.4,17.5c-0.5,2.6-1.4,8-3,9.8c-1.6,1.8-5.9,2.5-6.8-1.8c-0.9-4.2,2.2-15.5,2.6-19.1c0.4-3.6,3.1-20,3.8-21.6c0.6-1.6-0.2-5.2-1.2-2.8s-2.4,19-4,23c-1.6,4-5,15.4-5.6,18.4c-0.6,3-3.1,11.2-5.5,12.5c-2.4,1.2-5.2-1.2-5.5-4.6c-0.2-3.4,0.8-8.1,1.5-11.5c0.8-3.4,1-10.8,3.4-17.5c2.4-6.8,3.9-15.4,4.8-18.5c0.9-3.1-1.6-7.4-2.2-5.8c-0.6,1.6-7,20-7.4,22.8c-0.5,2.8-3,14.2-4.1,16.9c-1.1,2.6-4.5,4.9-7.1,1.4c-2.6-3.5-0.6-14.8,0-19c0.6-4.2,4.8-21.5,5.6-23.6c0.9-2.1,0.8-8.6,1.9-14c1.1-5.4,3.2-12.2,4-13.6c0.8-1.4,0.9-4.5-1.9-4.5s-8.2,1.1-12.2,4.9s-12.1,11.5-15.8,12.4c-3.6,0.9-8-0.5-8-2.9c0-2.4,5.1-8.1,6.6-9.9s4.4-5.9,10-8.6c5.6-2.8,8.5-8.5,14.2-12.1c5.8-3.6,23.5-14.2,26.4-15.2c1.3-0.5,3.3-1.2,5.5-3.4c0,0,0,0,0,0c2.5-2.5,5.4-6.9,8.2-14.7c0.6-1.8,1.3-3.5,2-5.3c1.8,4.4,7.2,6.7,12.4,8.9C874.5,636.2,883.7,638,889,637z"/>
                    </ScrollToSection>
                    <ScrollToSection loc='hand'>
                        <path id="frt_29" className="st0 blue-part" d="M1375.7,688.7c-1.3,3.8-7.2,1.5-10.2,0s-8.5-7.7-11.5-10c-3-2.3-11.7-5.3-12.2-2.7c-0.4,2.2,4.7,15.7,4.8,22.7c0.1,7,4.1,14.2,4.5,21.2c0.4,7,3.6,28.1,1.9,31.4s-6,2.2-7.5-1.5s-5.2-22.6-5.9-26.6c-0.6-4-4.1-17-5.5-15.8c-1.4,1.2,2.1,13.6,3,18.1c0.9,4.5,6.8,30.8,6.4,34.5c-0.4,3.8-2.1,8-6.2,5.9c-4.1-2.1-5.6-13.6-6.1-16.2c-0.5-2.6-4.2-17-5.5-20.8s-2.8-13-3-14.4c-0.2-1.4-3.2-1.9-2.8-0.2c0.5,1.6,4.1,18.8,4.4,21.6c0.2,2.9,3.2,16,3.2,20s-4,7.2-7.1,2.9c-3.1-4.4-5.1-16.5-7.1-22.9c-2-6.4-4.8-19.4-5-21.2c-0.2-1.9-2.9-7.2-3.8-5.9c-0.9,1.4,1.8,11.4,2.2,14.5c0.5,3.1,1.8,9.4,2.1,11.5c0.4,2.1,1.1,4.2,1.9,5.2s1.6,6.2,0.4,8.5s-6,1.9-8.1-6.1c-2.1-8-4.6-8.5-5.5-15.2c-0.9-6.8-4.4-15.1-5.8-21.2c-1.4-6.1-1.1-12.6-3.5-22.4c-1.3-5.4-2.5-11.9-3.3-17.2c-0.7-4.3-1.1-7.8-1.1-9c-0.1-2.8-4.5-12.2-5.8-15.5c-0.2-0.5-1-2.3-2.2-4.8c5.2,0.9,14.4-0.8,19.9-3.2c5.1-2.2,10.5-4.5,12.3-8.8c6.1,15.7,8.1,17.2,17.1,21.9c9.3,4.8,23.8,13.2,27.2,16.7c3.3,3.5,13.7,8.3,17,13.5C1372.9,682.2,1377.1,684.8,1375.7,688.7z"/>
                    </ScrollToSection>
                    <ScrollToSection loc='leg'>
                        <path id="frt_14" className="st0 dark-part" d="M500.3,831.3c-2,25-10.3,42.3-16,52.3c-5.7,10-6.7,12-7,23c-0.3,11-3.6,38.8-3.6,50.3s1.2,29,3.5,43.5s-1.2,69-4.5,96.5s-7.5,75.5-8.2,87.8s0.2,18,1,22.2c-3.5-15.5-39.5-13-46.2-8.5c2.2-6.2,2.5-15.5,0.7-27.2c-1.8-11.7-7.7-47-9-59s0.2-14.7-2-20.8s-10.8-27-11.8-35s0.1-39.2,3.1-56.8s5.4-31.1,4.2-33.9c-1.2-2.8-5.2-15.2-5.2-25c0-9.8-2.3-15.7-4.3-24c-2-8.3-5.5-28.2-7.2-39.8c-1.7-11.7-7.8-55.8-10.1-67s-5-60.2-3.8-71s1.5-28.5,0.2-31.5s-3.2-7.3-4.3-8.1c-1.2-0.8-2.8-1.9-6.9-1.6c-4.1,0.3-5.8,1-6.8,3.2s-4.3,3.7-4.1,12.3c0.2,8.7-0.4,17,0.4,21.3c0.8,4.3,1.3,21.3-0.4,43.2c-1.7,21.8-10.1,74.7-11.8,88.2c-1.6,12.3-7.2,41.1-9.6,52.3c-0.2,1.1-0.5,2-0.6,2.7c-2,8.2-2.8,20.8-4,26.8s-2.5,15-3.4,16.6c-0.4,0.8-1,2.1-1.4,3.5c-0.3,1.3-0.4,2.7,0.2,3.6c1.2,2,2.8,9.5,3.5,15.1c0.8,5.6,3.5,44.1,4.5,51.4s1.2,18.5-2.5,27.2s-8.8,22.1-9.5,25.9c-0.7,3.8-3.5,22.2-4.3,33.3c-0.8,11.2-8.5,53-8.3,58.3c0.1,4.5,1.4,11.2,2.4,15c-8.3-3.8-37.8-5.4-44.8,6.1c0.1-1.2,0.2-2.4,0.1-3.5c-0.2-4.5-4.1-66.5-7.1-87.5s-6.5-67-7.5-83c-0.7-11,2.7-32.8,4.1-53c0.3-4.5,0.3-8.9,0.2-13.1c-0.1-4.2-0.3-8.3-0.3-11.9c-0.5-23.5,0-47-6.5-61c-1-2.2-2.2-4.5-3.4-7.1c-6.6-13.9-15.3-34.4-16.1-69.4c-0.2-8.5-1-17.4-0.4-26.5c2.2-35.6,10.4-73.4,14.4-92.5c5-24,12-62,11.5-70c-0.3-4.4,0.8-19.6,2.8-34c1.7-11.8,4-23.1,6.7-27.3c2-3,3-5.3,3.4-7.2c11.6,11.5,30.4,34.5,100.5,34.5c70.1,0,86.4-20.5,98.8-33.5c1.7,4.5,9,22.5,10,36.2c1,13.7,3.2,43.8,5.3,53.7c2.2,9.8,14.7,79.7,17,92.7c1.3,7.3,4.2,27.3,5.2,47.8C501.9,804.2,501.2,820.4,500.3,831.3z"/>
                    </ScrollToSection>
                    <ScrollToSection loc='leg'>
                        <path id="frt_30" className="st0 dark-part" d="M1197.9,607.7c-4.8-18.7-9.2-31.6-10.1-33.3c-0.7-1.3-1.5-3.1-2.3-5c-4.1,8.3-27.2,39.6-104,39.6s-98.3-32.1-101.9-39.6c-1.8,4.3-3.3,8-4.2,10.3c-0.7,1.8-4.8,12.2-9.3,28.1c-8.9,31.5-19.8,84.9-10.4,137.1c1.9,10.8,29.7,157,32.4,171.8c0.5,2.4,1.2,5.8,2,10c3.1,15.3,8,40.6,8.2,61.1c0.4,2.1,0.9,4.1,1.5,6c-0.5-1.9-1-3.9-1.5-6c0.1,8.4-0.7,15.9-2.7,21.7c-9.5,27-3.2,94.8,0,112s21.2,99.8,24.1,121.5c0.3,2.2,0.4,4.6,0.5,7c7-6.9,20.7-9.9,28.9-8.1c5,1.1,8.3,3.6,11.1,7.5c-2.4-9.7-1.7-19.7-1-34.9c0.8-16.5,6.8-83.5,9.8-105.2s0.8-46.2-2.5-62c-2.8-13.7-5.3-34.2-3.7-45.5c0.2-1.7,0.6-3.2,1-4.5c3.2-9.2,7-21.2,7.2-44c0-4.2,0-9.9-0.1-16.4c-0.3-28.3-1.7-73.1-2.7-91.6c-0.5-9-1.7-28.9,3.6-60.6c2.4-14.4,13.4-54.9-9.2-82.8c15.3,17.1,28.6,12.5,40.7-0.7c-21.9,24.2-15.4,60.2-10.4,83.6c5,23.5,4,49.2,3.8,59.5c-0.2,7.9-2.7,56.3-3,88.5v0c-0.1,9.7,0,18,0.4,23.2c1.8,22.5,4.2,31.7,7.7,44.5c3.5,12.8-5.2,55.3-7,75.2c-1.8,19.8,5,54.5,5.2,66.3c0.2,11.8,3.2,42.5,4.8,56.8c1.7,14.3,2.3,41.2-0.7,53.5c3.3-5.5,5.9-9.1,12.1-10.4c8.8-1.9,21.8,0.7,28.3,8.6c0.5-9.5,5.2-38.2,14.8-79.2s12.8-69.8,14.5-100.5s-0.5-49.8-2.2-55s-4.8-19.5-4.8-31.2s2.1-30.8,4.1-39.2c1.3-5.2,2.4-11.8,3.5-18.4c0.6-3.9,1.2-7.7,1.9-11.3c1.7-9.7,18-92.7,21-108.7s14.6-62.2,14.6-105.2C1211.5,668.9,1204.4,633,1197.9,607.7z M1069.7,940.8c-0.3,1.2-0.7,2.4-1.1,3.4C1069,943.2,1069.4,942.1,1069.7,940.8z"/>
                    </ScrollToSection>
                    <ScrollToSection loc='foot'>
                        <path id="frt_15" className="st0 red-part" d="M311,1253.1c-0.1,0.6-0.2,1.3-0.3,1.9c-1.5,8.5-6.8,15.2-15.5,18.8c-3.8,10.5-3.8,15.5-4.5,21.3c-0.3,2.7-1.1,8.8-2.3,12.8c-1.4,4.7-3.2,7.3-5.2,8.8c-3.6,2.9-4.8,4-5.9,6.8c-1.1,2.8-7.9,10-12.3,10.6c-5.4,0.7-10.5,0.5-15.7-5.8c-2.9,2.9-6.8,3.1-10.8-1.8c-8.2,1.2-8-3-8.2-5c-3.3,0.4-4.3-1.4-4.3-2.5c-3,0.4-5.9-2.4-6.1-5.5s2.1-20.6,10.1-29.5s16.6-19.5,21-28.2c4.2-8.3,9.7-16.7,10.9-23.7c0.1-0.3,0.1-0.7,0.1-1c0.8-7.2-1.9-11.9-1.4-15.9c0.4-3,1.4-7.3,1.8-11.1c7-11.5,36.5-9.9,44.8-6.1c0.2,0.7,0.4,1.3,0.6,1.8c1.2,3.2,3.2,16.5,1.8,21.8c-1.3,5.3-0.8,8.3,0.8,12.5C312,1237.8,312.2,1245.3,311,1253.1z"/>
                    </ScrollToSection>
                    <ScrollToSection loc='foot'>
                        <path id="frt_16" className="st0 red-part" d="M507.3,1314.8c-2.5,4-4.2,3.7-5,3.3c-0.7,1.5-2.7,2.9-4.2,2.6c-0.1,1.9-3,6.6-7.9,5.2c-1.2,2.5-7.8,6.6-12.2,2.1c-1.8,3.6-9,8.4-16.8,4.8s-10.3-8.1-11.2-9.8s-1.2-2.8-3.7-4.7c-2.4-1.9-7.7-7.3-8.7-17.7c-1-10.3-3-18.2-3.8-21c-0.8-2.8-0.8-5.5-3.8-7c-1.5-0.8-5-2.8-8.2-5.8c-3.2-2.9-6.2-6.7-6.8-10.8c-1-8.2-1.2-17.3,0.7-21.5c1.8-4.2,1.5-11.8,1-13.5c-0.5-1.7,0.5-16.5,2.7-22.7c6.7-4.5,42.7-7,46.2,8.5c0.8,4.2-0.2,12.8-1.5,16.2s-1,3.8,1,9.2s4.7,12.8,9.3,20.2c4.7,7.3,5.8,11.7,12.5,18.7c6.7,7,15.2,17.7,16.3,21.8C504.3,1297.3,509.8,1310.8,507.3,1314.8z"/>
                    </ScrollToSection>
                    <ScrollToSection loc='foot'>
                        <path id="frt_31" className="st0 red-part" d="M1157.7,1320c0.2,4.5-4.7,6.8-7,6.5c-2.3-0.3-5.8,1.2-8.2,0.8c-2.3-0.3-6.8,0.9-7.5,0.1c-0.7-0.9-2.8-0.3-4,0.3c-1.2,0.6-6.7,1.2-7.7,0s-2.5-0.7-4-0.2s-11,2.5-15.3-2.2c-4.3-4.7-1.8-6.2-3-7.2c-1.2-1-2.2-7.2,0.2-11.5c2.3-4.3-2.2-4.7-0.3-14.2c1.8-9.5,3.8-10.8,1.8-18c-2-7.2-1.8-10,1.2-22.3c3.3-5.5,5.9-9.1,12.1-10.4c8.8-1.9,21.8,0.7,28.3,8.6c-0.5,9.5,1.8,19.2,0.5,26.2c-1.3,7-3.5,8.2-1.8,12.5c1.7,4.3,8,18.8,10,21.8S1157.6,1315.5,1157.7,1320z"/>
                    </ScrollToSection>
                    <ScrollToSection loc='foot'>
                        <path id="frt_32" className="st0 red-part" d="M1063.9,1316.7c-0.7,2-0.5,3.5-1,5s-3.2,6.5-5.8,6.7c-2.7,0.3-10.8,0.3-12.7-1.4c-1.8-1.7-7,2.2-10.7,0.7c-3.7-1.5-4-1.2-5.2-0.8c-1.2,0.3-12,1-13.5-0.2c-1.5-1.2-1.5,1.2-4.8-0.7c-3.3-1.8-6-4.5-2.8-9.7c3.2-5.2,9.5-12,12.5-22c0.8-2.5,1.3-4.3,1.7-5.7c1-3.9,0.3-4.2-1.3-9.8c-1.8-6.2,0-18-0.2-29c7-6.9,20.7-9.9,28.9-8.1c5,1.1,8.3,3.6,11.1,7.5c0.2,0.9,0.4,1.8,0.7,2.6c3.2,10.5,4,16.5,2.5,19s-3,6.8-1.8,11.2c0.7,2.5,1.8,6,2.4,9.5c0.4,2.8,0.6,5.5-0.1,7.7c-1.5,5-1.3,5.5,0,8.3C1065.1,1310.5,1064.6,1314.7,1063.9,1316.7z"/>
                    </ScrollToSection>

                    <path id="frt_2" className="st0" d="M340.5,102.2c8.6,0,14.5-4.2,14.5-9.4s-5.9-9.4-14.5-9.4S325,87.7,325,92.9S331.9,102.2,340.5,102.2z"/>
                    <path id="frt_3" className="st0" d="M388.5,102.2c8.6,0,15.5-4.2,15.5-9.4s-6.9-9.4-15.5-9.4S374,87.7,374,92.9S379.9,102.2,388.5,102.2z"/>
                    <path id="frt_4" className="st0" d="M299.8,106c-4,4-1.8,17-0.8,20.7s5.8,14.7,7.2,15.8s5.2,4.8,8.5-1.7c0-10-4.6-25.3-6.7-33C307,106,303.8,102,299.8,106z"/>
                    <path id="frt_5" className="st0" d="M412.6,141.4c3.8,6.4,8.9,3.2,10-1.8s7.6-7.9,6.8-23.6s-8-11.7-11-7.3C417.3,113.3,411.9,136.2,412.6,141.4z"/>
                    <path id="frt_6" className="st0" d="M355.4,105.2c-0.4,3.1-5,6.4-5.6,13.1c-0.4,4.7,6.2,7.5,10.2,6.5c5,2.6,6.8,0.6,9.9-0.6c5.8,0.9,8-3.2,8-7.8s-4.4-6.8-4.9-12.2s-3.4-7.6-3.1-13.5s-2.4-9.9-6.1-9.9c-5.2,0-6.3,7.9-5.9,10.6S355.8,102.1,355.4,105.2z"/>
                    <path id="frt_7" className="st0" d="M374.2,133.2c-2.3-2.7-6.2-1.7-7.9-0.8c-0.9,0.5-3.5,0.9-5.1-0.4s-6.1-0.1-7.4,1.6s-11.8,5.1-12.6,8.1s8.6,3.2,11,4.1s4.5,3.8,13.1,3.8s11-2.8,13.2-3.2s8.1-1.1,8.5-3.5S377.9,137.6,374.2,133.2z"/>
                    <path id="frt_8" className="st0" d="M307.7,244.2c15.7-0.8,41.2-2.2,45.3,3.7s15.8,6,19.7,0s38-6.2,50.8-4.3c5,0.7,9.8,0.8,14.4,0.4c11-1.1,20.4-5.1,25.3-10c-8.9,0.1-18.5-5.2-31-10.7c-16.1-7.1-24.6-15.9-25.2-18.6s0.1-34.5,0.9-44.5c-9.2,14.3-24,23-45.7,23S321,163.7,318,159.7c2.2,6.3,1.5,29.8,0.8,45.3c-8.5,15.2-40,24-48,27.5c2,1.7,10.7,6.6,20.9,9.5C296.8,243.5,302.4,244.4,307.7,244.2z"/>
                    <path id="frt_18" className="st0" d="M1070.9,130.9c0-4.8-5.5-8.8-13.5-8.8c-8,0-14.5,3.9-14.5,8.8s6.5,8.8,14.5,8.8C1065.4,139.7,1070.9,135.8,1070.9,130.9z"/>
                    <path id="frt_19" className="st0" d="M1106.2,139.7c8,0,14.5-3.9,14.5-8.8s-6.5-8.8-14.5-8.8c-8,0-13.5,3.9-13.5,8.8S1098.2,139.7,1106.2,139.7z"/>
                    <path id="frt_20" className="st0" d="M1021.2,139.1c-2.9,0.6-4.1,8.2-3.1,13.6s3.9,14.2,6.5,18.8c2.3,4.1,4.8,9.6,8.3,3.4c-2.3-9.4-4.1-20.4-4.6-31.6C1026,141.1,1023.1,138.7,1021.2,139.1z"/>
                    <path id="frt_21" className="st0" d="M1136,174.6c1.5-2.4,4.4-8.9,5.8-12.6s2.9-14.2,2.4-18.9s-4.8-4.9-6.5-2.2c-0.8,1.2-2.2,1.5-3.6,1.3c-0.5,6.2-2.1,18.9-4.7,31.5C1131.8,179,1134.8,176.5,1136,174.6z"/>
                    <path id="frt_22" className="st0" d="M1068,158.8c-0.4,4.4,5.8,7,9.6,6.1c4.7,2.5,6.3,0.6,9.2-0.6c5.4,0.8,7.5-3,7.5-7.2c0-4.2-4.1-6.3-4.6-11.4s-3.2-7.1-2.9-12.6s-2.2-9.2-5.7-9.2c-4.9,0-5.9,7.4-5.5,9.9c0.4,2.6-2,9.9-2.3,12.8S1068.6,152.4,1068,158.8z"/>
                    <path id="frt_23" className="st0" d="M1090.5,174.5c-2-2.4-5.5-1.4-7-0.7c-0.8,0.4-3.1,0.8-4.6-0.3s-5.5-0.1-6.6,1.4c-1.1,1.5-10.5,5.5-11.2,8.1c-0.8,2.6,7.7,3.9,9.8,4.6c2.1,0.8,4,3.3,11.7,3.3c7.7,0,9.8-2.4,11.8-2.9c2-0.4,7.2-2,7.6-4.1C1102.3,182,1093.7,178.4,1090.5,174.5z"/>
                    <path id="frt_24" className="st0" d="M1055.2,277.2c7.7-1.3,14,3.7,18.3,7.7s11.7,4,15.3,0s12-9.3,24.3-7.3s16.3-1,26.3-6c13.2-6.6,19.5-9.1,36.7-14.5c-4.2-0.3-8.4-0.4-12.5-0.6c-17.5-1-39-4.6-42.5-13.3c-4.1-10-3.2-27.4-0.2-41.3c-1.7,3.8-6.6,8.8-10.5,12.2c-7.2,6.4-15.6,10.2-27.3,10.2c-11.4,0-18.5-3.1-26-9c-4.5-3.5-10.4-7.9-13.8-13.2c0.3,2.2,2.8,18.8,1.8,32.2c-0.9,13.4-10.8,16.6-31.6,20.6c-8.1,1.5-21.4,3-29.4,3.7c20.7,3.5,43.3,10.5,47.3,13.5S1047.6,278.5,1055.2,277.2z"/>
                </svg>
            </div>
        </div>
    <div className="clear"></div>
</Fragment>
    );
};

export const query = graphql`
  fragment BodyNavItem on ContentfulPartieDuCorps {
    id
    nom
    slug
  }
`;

export default Mannequin;